<template>
  <v-container
    class="ma-0 pa-0"
    fluid
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ this.$t('generic.lang_assign_replication_template') }}
        </span>
      </v-card-title>

      <v-divider class="ma-0 pa-0" />

      <v-card-text class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
          <v-col
            :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
            class="ma-0 pa-0"
            cols="12"
          >
            <div class="card-header-tab card-header">
              <div
                class="card-header-title font-size-lg text-capitalize font-weight-normal"
              >
                <b-input-group width="100%">
                  <b-form-input
                    :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :placeholder="
                      $t('datatables.lang_search') +
                      ' ' +
                      $t('generic.lang_systemID')
                    "
                    @focus="showTouchKeyboard"
                    v-model="search"
                  />
                </b-input-group>
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <v-data-table
              :headers="this.datatableHeaders"
              :items="this.delegated"
              :items-per-page="5"
              :loading="this.loading"
              :search="this.search"
            >
              <template v-slot:item.tmplId="{ item }">
                <v-row
                  align="center"
                  align-content="center"
                  justify="center"
                  class="pa-0 ma-0"
                >
                  <v-select
                    v-model="item.tmplId"
                    :items="templates"
                    @change="update(item)"
                    item-text="name"
                    item-value="tmplId"
                    clearable
                    outlined
                    dense
                    :placeholder="$t('erp.lang_selectedTemplate')"
                    class="pt-5"
                    :class="[$vuetify.theme.dark ? 'dark-bg' : '']"
                  ></v-select>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidthExpanded"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from '@/config';
import { mapState } from 'vuex';
import mixin from '@/mixins/KeyboardMixIns';
//components
import Datatable from '../datatable/Datatable';
import { Events } from '@/plugins/events';

export default {
  name: 'AssignTemplateComponent.vue',
  mixins: [mixin],
  components: {
    Datatable,
  },

  data: () => {
    return {
      ENDPOINTS,
      loading: false,
      search: '',
      selected: {},
      delegated: [],
      templates: [],
    };
  },
  computed: {
    ...mapState(['touchkeyboard']),
    datatableHeaders: function () {
      return [
        {
          text: this.$t('generic.lang_systemID'),
          value: 'bs_id',
          sortable: true,
          // class: "d-none",
          // cellClass: "d-none",
        },
        {
          text: this.$t('erp.lang_selectedTemplate'),
          value: 'tmplId',
          align: 'center',
          sortable: false,
        },
      ];
    },
  },

  mounted() {
    this.loadData();
    this.loadTemplates();
  },

  methods: {
    loadTemplates() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.DATATABLES.POS_REPLICATION.REPLICATION_TEMPLATE)
        .then((res) => {
          let templates = [];
          res.data.aaData.forEach((data) => {
            templates.push({ tmplId: data[0], name: data[1] });
          });
          this.templates = templates;
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadData() {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.POS_REPLICATION.ASSIGN_TEMPLATE.GET)
        .then((res) => {
          const isDelegated = (bs_id) => {
            return this.delegated.some((el) => el.bs_id === bs_id);
          };

          this.delegated = Array.isArray(res.data.delegated)
            ? res.data.delegated
            : [];

          (res.data.systems ?? []).forEach((data) => {
            if (!isDelegated(data.bs_id)) {
              this.delegated.push({ bs_id: data.bs_id, tmplId: '' });
            }
          });
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(item) {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.POS_REPLICATION.ASSIGN_TEMPLATE.UPDATE, {
          systemId: item.bs_id,
          templateId: item.tmplId,
        })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            Events.$emit('showSnackbar', {
              color: 'success',
              message: this.$t('generic.lang_success'),
            });
          } else {
            Events.$emit('showSnackbar', {
              color: 'error',
              message: this.$t('generic.lang_errorOccurred'),
            });
          }
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            color: 'error',
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.card-header-title {
  width: 100% !important;
}
</style>
