<template>
  <div>
    <page-title
      :heading="$t('generic.lang_assign_replication_group')"
      :subheading="$t('generic.lang_assign_replication_group')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <assign-template-component />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import AssignTemplateComponent from "../../components/posReplication/AssignTemplateComponent.vue";

export default {
  name: "AssignTemplateView",
  components: {
    PageTitle,
    AssignTemplateComponent,
  },

  data: () => ({
    icon: "pe-7s-box1 icon-gradient bg-tempting-azure",
  }),
};
</script>
